/*!
 * @license
 * Copyright Squiz Australia Pty Ltd. All Rights Reserved.
 */

//--------------------
// Plugins
//--------------------

@import 'NodeModules/modern-normalize/modern-normalize.css';

//--------------------
// Design helpers
//--------------------

@import 'imports/sds/colours.scss';
@import 'imports/sds/core.scss';
@import 'imports/sds/typography.scss';
@import 'imports/sds/buttons.scss';
@import 'imports/sds/patterns.scss';
@import 'imports/functions.scss';
@import 'imports/mixins.scss';
@import 'imports/base.scss';

//--------------------
// Modules
//--------------------

@import '../modules/badge/css/styles.scss';
@import '../modules/banner-with-search/css/2-col.scss';
@import '../modules/banner-with-search/css/styles.scss';
@import '../modules/breadcrumbs/css/styles.scss';
@import '../modules/call-to-action/css/styles.scss';
@import '../modules/cards-collection/css/styles.scss';
@import '../modules/data-listing/css/styles.scss';
@import '../modules/dropdown/css/styles.scss';
@import '../modules/footer/css/styles.scss';
@import '../modules/header/css/styles.scss';
@import '../modules/header-navigation/css/styles.scss';
@import '../modules/header-search/css/styles.scss';
@import '../modules/info-panels/css/styles.scss';
@import '../modules/integration-card/css/styles.scss';
@import '../modules/integrations-details/css/styles.scss';
@import '../modules/left-navigation/css/styles.scss';
@import '../modules/search-results/css/styles.scss';
@import '../modules/skip-links/css/styles.scss';
@import '../modules/spinner/css/styles.scss';
