//--------------------
// Typography - Headings
//--------------------

@mixin sds-base-heading($level) {
  @if $level == 1 {
    font-size: $sds-h1-font-size;
    font-weight: $sds-h1-font-weight;
    letter-spacing: $sds-h1-letter-spacing;
    line-height: $sds-h1-line-height;
  }

  @if $level == 2 {
    font-size: $sds-h2-font-size;
    font-weight: $sds-h2-font-weight;
    letter-spacing: $sds-h2-letter-spacing;
    line-height: $sds-h2-line-height;
  }

  @if $level == 3 {
    font-size: $sds-h3-font-size;
    font-weight: $sds-h3-font-weight;
    letter-spacing: $sds-h3-letter-spacing;
    line-height: $sds-h3-line-height;
  }

  @if $level == 4 {
    font-size: $sds-h4-font-size;
    font-weight: $sds-h4-font-weight;
    letter-spacing: $sds-h4-letter-spacing;
    line-height: $sds-h4-line-height;
  }

  @if $level == 5 {
    font-size: $sds-h5-font-size;
    font-weight: $sds-h5-font-weight;
    letter-spacing: $sds-h5-letter-spacing;
    line-height: $sds-h5-line-height;
  }

  @if $level == 6 {
    font-size: $sds-h6-font-size;
    font-weight: $sds-h6-font-weight;
    letter-spacing: $sds-h6-letter-spacing;
    line-height: $sds-h6-line-height;
  }
}

@mixin sds-heavy-heading($level) {
  @if $level == 1 {
    font-size: $sds-h1-heavy-font-size;
    font-weight: $sds-h1-heavy-font-weight;
    letter-spacing: $sds-h1-heavy-letter-spacing;
    line-height: $sds-h1-heavy-line-height;
  }

  @if $level == 2 {
    font-size: $sds-h2-heavy-font-size;
    font-weight: $sds-h2-heavy-font-weight;
    letter-spacing: $sds-h2-heavy-letter-spacing;
    line-height: $sds-h2-heavy-line-height;
  }

  @if $level == 3 {
    font-size: $sds-h3-heavy-font-size;
    font-weight: $sds-h3-heavy-font-weight;
    letter-spacing: $sds-h3-heavy-letter-spacing;
    line-height: $sds-h3-heavy-line-height;
  }

  @if $level == 4 {
    font-size: $sds-h4-heavy-font-size;
    font-weight: $sds-h4-heavy-font-weight;
    letter-spacing: $sds-h4-heavy-letter-spacing;
    line-height: $sds-h4-heavy-line-height;
  }

  @if $level == 5 {
    font-size: $sds-h5-heavy-font-size;
    font-weight: $sds-h5-heavy-font-weight;
    letter-spacing: $sds-h5-heavy-letter-spacing;
    line-height: $sds-h5-heavy-line-height;
  }

  @if $level == 6 {
    font-size: $sds-h6-heavy-font-size;
    font-weight: $sds-h6-heavy-font-weight;
    letter-spacing: $sds-h6-heavy-letter-spacing;
    line-height: $sds-h6-heavy-line-height;
  }
}

//--------------------
// Typography - Text
//--------------------

@mixin sds-text($size, $weight: 'regular') {
  @if $size == 'xlg' {
    @if $weight == 'regular' {
      font-size: $sds-text-xlg-regular-font-size;
      font-weight: $sds-text-xlg-regular-font-weight;
      letter-spacing: $sds-text-xlg-regular-letter-spacing;
      line-height: $sds-text-xlg-regular-line-height;
    }

    @if $weight == 'semibold' {
      font-size: $sds-text-xlg-semibold-font-size;
      font-weight: $sds-text-xlg-semibold-font-weight;
      letter-spacing: $sds-text-xlg-semibold-letter-spacing;
      line-height: $sds-text-xlg-semibold-line-height;
    }

    @if $weight == 'bold' {
      font-size: $sds-text-xlg-bold-font-size;
      font-weight: $sds-text-xlg-bold-font-weight;
      letter-spacing: $sds-text-xlg-bold-letter-spacing;
      line-height: $sds-text-xlg-bold-line-height;
    }
  }

  @if $size == 'lg' {
    @if $weight == 'regular' {
      font-size: $sds-text-lg-regular-font-size;
      font-weight: $sds-text-lg-regular-font-weight;
      letter-spacing: $sds-text-lg-regular-letter-spacing;
      line-height: $sds-text-lg-regular-line-height;
    }

    @if $weight == 'semibold' {
      font-size: $sds-text-lg-semibold-font-size;
      font-weight: $sds-text-lg-semibold-font-weight;
      letter-spacing: $sds-text-lg-semibold-letter-spacing;
      line-height: $sds-text-lg-semibold-line-height;
    }

    @if $weight == 'bold' {
      font-size: $sds-text-lg-bold-font-size;
      font-weight: $sds-text-lg-bold-font-weight;
      letter-spacing: $sds-text-lg-bold-letter-spacing;
      line-height: $sds-text-lg-bold-line-height;
    }
  }

  @if $size == 'md' {
    @if $weight == 'regular' {
      font-size: $sds-text-md-regular-font-size;
      font-weight: $sds-text-md-regular-font-weight;
      letter-spacing: $sds-text-md-regular-letter-spacing;
      line-height: $sds-text-md-regular-line-height;
    }

    @if $weight == 'semibold' {
      font-size: $sds-text-md-semibold-font-size;
      font-weight: $sds-text-md-semibold-font-weight;
      letter-spacing: $sds-text-md-semibold-letter-spacing;
      line-height: $sds-text-md-semibold-line-height;
    }

    @if $weight == 'bold' {
      font-size: $sds-text-md-bold-font-size;
      font-weight: $sds-text-md-bold-font-weight;
      letter-spacing: $sds-text-md-bold-letter-spacing;
      line-height: $sds-text-md-bold-line-height;
    }
  }

  @if $size == 'sm' {
    @if $weight == 'regular' {
      font-size: $sds-text-sm-regular-font-size;
      font-weight: $sds-text-sm-regular-font-weight;
      letter-spacing: $sds-text-sm-regular-letter-spacing;
      line-height: $sds-text-sm-regular-line-height;
    }

    @if $weight == 'semibold' {
      font-size: $sds-text-sm-semibold-font-size;
      font-weight: $sds-text-sm-semibold-font-weight;
      letter-spacing: $sds-text-sm-semibold-letter-spacing;
      line-height: $sds-text-sm-semibold-line-height;
    }

    @if $weight == 'bold' {
      font-size: $sds-text-sm-bold-font-size;
      font-weight: $sds-text-sm-bold-font-weight;
      letter-spacing: $sds-text-sm-bold-letter-spacing;
      line-height: $sds-text-sm-bold-line-height;
    }
  }
}

//--------------------
// Pseudo styles
//--------------------

@mixin sds-base-focus-shadow() {
  box-shadow: $sds-shadow-outline-blue;
}

@mixin sds-base-focus() {
  @include sds-base-focus-shadow;
  border-radius: $sds-border-radius;
  outline: 0;
}

//--------------------
// Layouts
//--------------------

@mixin flex-vertical() {
  align-items: center;
  display: flex;
}

@mixin flex-inline-vertical() {
  align-items: center;
  display: inline-flex;
}

//--------------------
// Resets
//--------------------

@mixin button-reset() {
  background: transparent;
  border: none;
  cursor: pointer;
  letter-spacing: inherit;
  padding: 0;
  position: relative;
}

@mixin list-reset() {
  list-style-type: none;
  margin: 0;
  padding: 0;
}

//--------------------
// Inputs
//--------------------

@mixin sds-base-input() {
  background: $sds-white;
  border: 2px solid $sds-grey-300;
  border-radius: $sds-border-radius;
  color: $sds-grey-800;
  margin: 0;
  padding: $sds-spacing-unit-2 px-rem(10);

  &::placeholder {
    color: $sds-grey-400;
  }
}

//--------------------
// Buttons
//--------------------

@mixin sds-icon-button() {
  border-radius: $sds-border-radius;
  display: inline-block;
  padding: px-rem(6) $sds-spacing-unit-2;

  &:hover {
    background-color: $sds-grey-100;
  }
}

@mixin sds-chip() {
  @include sds-text('md', 'semibold');
  @include flex-vertical;
  border-radius: px-rem(100);
  padding: $sds-spacing-unit-1 $sds-spacing-unit-1 $sds-spacing-unit-1 $sds-spacing-unit-3;
}

//--------------------
// Containers
//--------------------

@mixin sds-grey-white-container() {
  background: $sds-white;
  border: 1px solid $sds-grey-200;
  border-radius: $sds-border-radius;
}

@mixin sds-grey-white-container-lg() {
  background: $sds-white;
  border: 1px solid $sds-grey-200;
  border-radius: px-rem(8);
}
