//!
// @license
// Copyright Squiz australia Pty Ltd. all Rights Reserved.
//

//--------------------
// Global
//--------------------

.badge {
  @include sds-text('sm', 'bold');
  background: $sds-white;
  border-radius: $sds-border-radius;
  display: inline-flex;
}

.badge-label {
  display: inline-block;
  padding: $sds-spacing-unit-05 $sds-spacing-unit-2;
  text-transform: uppercase;
}

.badge--purple {
  border: 1px solid $sds-purple-300;
  color: $sds-purple-300;
}

.badge--teal {
  border: 1px solid $sds-teal-300;
  color: $sds-teal-300;
}
