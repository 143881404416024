//!
// @license
// Copyright Squiz australia Pty Ltd. all Rights Reserved.
//

//--------------------
// Global
//--------------------

.footer {
  border-top: 1px solid $sds-grey-200;
  color: $sds-grey-600;
  padding: $sds-spacing-unit-6 0;
}

.footer__logo-link {
  display: inline-block;
}

.footer__logo {
  display: flex;
}

.footer__corporate-list {
  @include list-reset;
}

.footer__corporate-link {
  color: $sds-grey-600;
  text-decoration: none;

  &:hover {
    text-decoration: underline;
  }
}

//--------------------
// Mobile
//--------------------

@media screen and (max-width: ($device-tablet - 1)) {
  .footer__logo,
  .footer__corporate-item {
    margin-bottom: $sds-spacing-unit-3;
  }
}

//--------------------
// Tablet
//--------------------

@media screen and (min-width: $device-tablet) {
  .footer__container,
  .footer__corporate,
  .footer__corporate-list {
    @include flex-vertical;
  }

  .footer__logo {
    flex-grow: 1;
  }

  .footer__corporate-item {
    margin-right: $sds-spacing-unit-5;
  }
}
