//!
// @license
// Copyright Squiz australia Pty Ltd. all Rights Reserved.
//

//--------------------
// Global
//--------------------

.dropdown-button {
  @include flex-vertical;
  @include sds-text('lg', 'semibold');
  border-left: 1px solid $sds-grey-200;
  color: $sds-grey-700;
  padding: $sds-spacing-unit-4;
  text-align: left;
  width: 100%;

  span {
    flex-grow: 1;
  }
}

.dropdown-menu {
  background: $sds-white;
  border-radius: $sds-border-radius;
  box-shadow: $sds-shadow-medium;
  display: none;
  margin-top: $sds-spacing-unit-2;
  position: absolute;
  width: 100%;

  &.active {
    display: block;
  }
}

.dropdown-menu__list {
  @include list-reset;
  display: flex;
  flex-direction: column;

  li {
    margin: 2px;
  }
}

.dropdown-menu__item {
  @include flex-vertical;
  background: $sds-white;
  border-radius: $sds-border-radius;
  color: $sds-grey-600;
  margin: 0;
  padding: px-rem(15) $sds-spacing-unit-5;
  text-decoration: none;
  width: 100%;

  &:hover {
    background-color: $sds-grey-100;
    color: $sds-grey-800;
  }
}

//--------------------
// Mobile Only
//--------------------

@media screen and (max-width: ($device-tablet - 1)) {
  .data-list__form {
    .dropdown-button {
      border: 1px solid $sds-grey-200;
      border-radius: $sds-border-radius;
      margin-top: $sds-spacing-unit-2;
    }
  }
}
