//!
// @license
// Copyright Squiz australia Pty Ltd. all Rights Reserved.
//

//--------------------
// Global
//--------------------

.integration-card__link {
  background: $sds-white;
  border: 1px solid $sds-grey-300;
  border-radius: $sds-spacing-unit-2;
  display: block;
  padding: $sds-spacing-unit-4;
  text-decoration: none;
  width: 100%;

  &:hover {
    .integration-card__heading {
      text-decoration: underline;
    }
  }
}

.integration-card__logo {
  display: flex;

  img {
    height: px-rem(52);
  }
}

.integration-card__heading {
  @include sds-text('xlg', 'semibold');
  color: $sds-grey-800;
  display: block;
  margin-bottom: $sds-spacing-unit-1;
}

.integration-card__content {
  @include sds-text('sm', 'regular');
  color: $sds-grey-600;
}

//--------------------
// Mobile Only
//--------------------

@media screen and (max-width: ($device-tablet - 1)) {
  .integration-card__link {
    display: flex;
    flex-direction: row-reverse;
  }

  .integration-card__logo {
    align-self: center;
    flex-shrink: 0;
  }

  .integration-card__body {
    padding-right: $sds-spacing-unit-3;
  }
}

//--------------------
// Tablet
//--------------------

@media screen and (min-width: $device-tablet) {
  .integration-card__link {
    height: 100%;
  }

  .integration-card__logo {
    margin-bottom: px-rem(10);
  }
}
