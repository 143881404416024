//!
// @license
// Copyright Squiz australia Pty Ltd. all Rights Reserved.
//

//--------------------
// Global
//--------------------

.banner-with-search__wrapper {
  padding: $sds-spacing-unit-5 0;

  .banner {
    background-color: $sds-grey-900;
    border-radius: $sds-spacing-unit-4;
    display: flex;
    position: relative;
  }

  .banner__img {
    border-radius: $sds-spacing-unit-4;
    height: 100%;
    object-fit: cover;
    position: absolute;
  }

  .banner__content {
    align-items: center;
    display: flex;
    height: 100%;
    justify-content: center;
    padding: 0 $sds-spacing-unit-7;
    width: 100%;
    z-index: 1;
  }

  .banner__content-text {
    @include sds-base-heading(1);
    color: $sds-white;
    font-weight: 400;
    text-align: center;
  }

  .banner__form {
    position: relative;
    width: 100%;

    input {
      @include sds-base-input;
      @include sds-text('lg', 'semibold');
      border: 2px solid $sds-dxp-secondary-1;
      border-radius: px-rem(8);
      padding: $sds-spacing-unit-4 px-rem(27) $sds-spacing-unit-4 px-rem(56);
      width: 100%;
    }

    .banner__submit {
      left: $sds-spacing-unit-5;
      position: absolute;
      top: 50%;
      transform: translateY(-50%);

      .svg-wrapper svg {
        fill: $sds-dxp-primary;
      }
    }
  }

  .banner--search {
    height: px-rem(229);

    .banner__content {
      display: flex;
      flex-direction: column;

      .banner__content-text,
      .banner__content-subtext {
        text-align: left;
        width: 100%;
      }

      .banner__content-text {
        display: block;
        margin-bottom: $sds-spacing-unit-2;
      }

      .banner__content-subtext {
        color: $sds-white;
        margin-top: $sds-spacing-unit-2;
      }
    }
  }
}

//--------------------
// Mobile Only
//--------------------

@media screen and (max-width: ($device-tablet - 1)) {
  .banner-with-search__wrapper {
    .banner__content-text {
      display: none;
    }

    .banner:not(.banner--search) {
      height: px-rem(136);
    }
  }
}

//--------------------
// Tablet
//--------------------

@media screen and (min-width: $device-tablet) {
  .banner-with-search__wrapper {
    .banner {
      height: px-rem(270);
    }

    .banner__content {
      flex-direction: column;
      margin: 0 auto;
      max-width: px-rem(510);
    }

    .banner__content-text {
      margin-bottom: px-rem(38);
    }

    .banner--search {
      .banner__content {
        max-width: px-rem(876);
      }
    }
  }
}
