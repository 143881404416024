//!
// @license
// Copyright Squiz australia Pty Ltd. all Rights Reserved.
//

//--------------------
// Global
//--------------------

.integration__header {
  display: flex;
}

.integration__logo {
  display: flex;
  flex-shrink: 0;
  margin-right: $sds-spacing-unit-2;

  img {
    height: px-rem(78);
    width: px-rem(78);
  }
}

.integration__title {
  h1 {
    margin: 0;
  }
}

.integration__description {
  @include sds-text('lg', 'semibold');
  color: $sds-grey-600;
  font-weight: 500;
}

.integration__body {
  margin-bottom: $sds-spacing-unit-6;
}

.integration__content {
  padding: $sds-spacing-unit-6 0;
}

.integrations__content-heading {
  @include flex-vertical;
}

.integration__content-title {
  @include sds-base-heading(3);
  display: block;
  padding: $sds-spacing-unit-6 0;
}

.integration__tags {
  @include list-reset;
  display: inline-flex;
  flex-direction: column;
  padding-top: $sds-spacing-unit-6;
}

.integration__meta {
  background: $sds-grey-100;
  border-bottom: 1px solid $sds-white;
  display: flex;

  &:first-child {
    border-radius: $sds-border-radius $sds-border-radius 0 0;
  }

  &:last-child {
    border-bottom: none;
    border-radius: 0 0 $sds-border-radius $sds-border-radius;
  }
}

.integration__meta-title {
  @include sds-text('sm', 'semibold');
  color: $sds-grey-600;
  padding: $sds-spacing-unit-3;
  text-transform: uppercase;
}

.integration__meta-list {
  @include list-reset;
  @include flex-vertical;
  flex-wrap: wrap;
  margin-right: $sds-spacing-unit-1;
  margin-top: $sds-spacing-unit-2;
}

.integration__meta-item {
  margin: 0 $sds-spacing-unit-2 $sds-spacing-unit-2 0;
}

//--------------------
// Desktop
//--------------------

@media screen and (min-width: $device-desktop) {
  .integration__content {
    padding-bottom: 0;
  }
}
