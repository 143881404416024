//!
// @license
// Copyright Squiz australia Pty Ltd. all Rights Reserved.
//

//--------------------
// Global
//--------------------

.search-help__container {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
}

.search-help__tabs-list {
  @include list-reset;
  @include flex-vertical;
  overflow-x: auto;
  padding: 0 $sds-page-gutter;
}

.search-help__tabs-item {
  flex-shrink: 0;
  margin-right: $sds-spacing-unit-4;
}

.search-help__tabs-link {
  @include sds-text('lg', 'regular');
  color: $sds-grey-800;
  display: block;
  padding: px-rem(7) $sds-spacing-unit-2;
  position: relative;
  text-decoration: none;

  &.active,
  &:hover {
    &::after {
      background: $sds-grey-800;
      border-radius: px-rem(2) px-rem(2) 0 0;
      bottom: 0;
      content: ' ';
      height: px-rem(3);
      left: 0;
      position: absolute;
      width: 100%;
    }
  }

  &.active {
    font-weight: $sds-text-lg-bold-font-weight;
  }
}

.search-help__results {
  background: $sds-grey-50;
  border-top: 1px solid $sds-grey-300;
  flex-grow: 1;
  padding: $sds-spacing-unit-6 $sds-page-gutter;

  .container-page-padded {
    position: relative;
  }
}

.search-filters__container {
  margin-bottom: $sds-spacing-unit-4;
}

.search-filters {
  @include flex-vertical;
  justify-content: space-between;
  padding: $sds-spacing-unit-4;
}

.search-filters__enablement {
  @include flex-vertical;
}

.search-filters__heading {
  font-weight: $sds-h6-font-weight;
}

.search-filters__control {
  @include sds-icon-button;
  background: $sds-grey-100;
  margin-left: $sds-spacing-unit-4;
}

.search-filters__applied {
  border-top: 1px solid $sds-grey-200;
  padding: $sds-spacing-unit-3;
}

.applied-filters__list {
  @include list-reset;
  @include flex-vertical;
  flex-wrap: wrap;
}

.applied-filters__item {
  margin: $sds-spacing-unit-1;
}

.applied-filter {
  @include sds-chip;
  background: $sds-blue-300;
  color: $sds-white;
}

.applied-filter__name {
  display: inline-block;
  margin-right: px-rem(6);
}

.applied-filter__action {
  text-decoration: none;

  svg {
    fill: $sds-blue-200;
  }
}

.filter-panel__sections {
  overflow-y: auto;
  padding: $sds-page-gutter;
}

.filter-section {
  padding-bottom: $sds-spacing-unit-4;
}

.filter-section__heading {
  @include sds-base-heading(3);
  border-bottom: 1px solid $sds-grey-300;
  display: block;
  margin-bottom: $sds-spacing-unit-3;
  padding-bottom: $sds-spacing-unit-2;
}

.filter-section__list {
  @include list-reset;
}

.filter-section__item {
  margin-bottom: $sds-spacing-unit-3;

  &.filter-item--sub {
    margin-left: $sds-spacing-unit-3;
  }
}

.filter-item__link {
  color: $sds-grey-800;
  text-decoration: none;

  &:hover {
    .filter-item__label {
      text-decoration: underline;
    }
  }
}

.filter-item__label {
  font-weight: $sds-text-md-semibold-font-weight;
}

.filter-section--checkbox {
  .filter-item__link {
    padding-left: $sds-spacing-unit-8;
    position: relative;

    &::before {
      background: $sds-white;
      border: 2px solid $sds-grey-300;
      border-radius: $sds-border-radius;
      content: ' ';
      height: $sds-spacing-unit-5;
      left: 0;
      position: absolute;
      top: 0;
      width: $sds-spacing-unit-5;
    }

    &.filter-item--selected {
      &::before {
        background-color: $sds-blue-300;
        border-color: $sds-blue-300;
      }

      &::after {
        border-bottom: 2px solid $sds-white;
        border-left: 2px solid $sds-white;
        content: ' ';
        height: px-rem(6);
        left: $sds-spacing-unit-1;
        position: absolute;
        top: px-rem(6);
        transform: rotate(-45deg);
        width: $sds-spacing-unit-3;
      }
    }
  }
}

.search-results__list {
  @include list-reset;
}

.search-results__item {
  @include sds-grey-white-container-lg;
  margin-bottom: $sds-spacing-unit-4;

  &:last-child {
    margin-bottom: 0;
  }
}

.results-item {
  @include flex-vertical;
  justify-content: space-between;
  padding: $sds-spacing-unit-5;
}

.results-item__title,
.results-item__description {
  display: block;
}

.results-item__title {
  @include sds-base-heading(2);
  color: $sds-grey-800;
  margin-bottom: $sds-spacing-unit-1;
  text-decoration: none;

  &:hover {
    text-decoration: underline;
  }
}

.results-item__description {
  @include sds-text('lg', 'regular');
  color: $sds-grey-600;
  margin: $sds-spacing-unit-2 0;
}

.results-item__summary {
  @include sds-text('sm');
  word-break: break-word;
}

.results-item__category-list {
  @include list-reset;
  @include flex-vertical;
  margin-top: px-rem(14);
}

.results-item__category-item {
  margin: $sds-spacing-unit-1;

  &:first-child {
    margin-left: 0;
  }

  &:last-child {
    margin-right: 0;
  }
}

.results-item__category {
  display: flex;
}

.results-item__category-content {
  @include flex-inline-vertical;
  background: $sds-grey-200;
  border-radius: px-rem(100);
  padding: px-rem(2) $sds-spacing-unit-2;
}

.results-item__category-icon {
  margin-right: px-rem(4);

  svg {
    height: px-rem(18);
    width: px-rem(18);
  }
}

.results-item__category-name {
  font-weight: $sds-text-md-semibold-font-weight;
}

.results-item__flair {
  flex-shrink: 0;
  margin-left: $sds-spacing-unit-5;
}

.results-item__thumbnail {
  display: flex;
  max-width: px-rem(60);
}

.search-results__per-page {
  @include flex-vertical;
}

.results-per-page__options {
  margin-left: $sds-spacing-unit-2;

  select {
    background: transparent;
    border: none;
    color: $sds-grey-700;
    font-weight: $sds-text-md-semibold-font-weight;
    padding: $sds-spacing-unit-1 $sds-spacing-unit-3;
  }
}

.search-results__pagination {
  @include flex-vertical;
}

.search-results__pagination--wrapper {
  color: $sds-grey-700;
  font-weight: $sds-text-md-semibold-font-weight;
}

.results-pagination__current {
  display: block;
  margin-right: $sds-spacing-unit-7;
}

.results-pagination__list {
  @include list-reset;
  @include flex-vertical;

  .pagination--prev {
    margin-right: $sds-spacing-unit-5;
    transform: rotate(-90deg);
  }

  .pagination--next {
    transform: rotate(90deg);
  }
}

.results-pagination__link {
  display: block;
}

.search-help--empty {
  .search-filters__panel {
    display: none;
  }

  .search-results__container {
    margin-left: 0;
    padding-left: 0;
  }
}

.search-results__empty {
  padding: px-rem(50) 0;
}

.search-results__empty-content {
  @include flex-vertical;
  flex-direction: column;
  margin: 0 auto;
  max-width: px-rem(288);
}

.search-results__empty-icon {
  @include flex-vertical;
  background: $sds-grey-200;
  border-radius: 100%;
  height: px-rem(176);
  justify-content: center;
  margin-bottom: $sds-spacing-unit-4;
  width: px-rem(176);

  svg {
    height: px-rem(100);
    width: px-rem(100);
  }
}

.search-results__empty-heading {
  @include sds-base-heading(1);
  display: block;
  margin-bottom: $sds-spacing-unit-2;
}

.search-results__empty-prompt {
  @include sds-text('lg', 'regular');
  text-align: center;
}

//--------------------
// Mobile Only
//--------------------

@media screen and (max-width: ($device-tablet - 1)) {
  .search-filters__container {
    @include sds-grey-white-container;
  }

  .search-filters__panel {
    display: none;

    &.active {
      background: $sds-white;
      display: flex;
      flex-direction: column;
      height: 100%;
      left: 0;
      position: fixed;
      top: 0;
      width: 100%;
      z-index: 12;
    }
  }

  .filter-panel__header {
    @include flex-vertical;
    border-bottom: 1px solid $sds-grey-300;
    justify-content: space-between;
    padding: $sds-spacing-unit-4 $sds-page-gutter;
  }

  .filter-panel__heading {
    @include sds-text('lg', 'semibold');
    color: $sds-grey-700;
  }

  .filter-panel__control {
    svg {
      fill: $sds-grey-700;
    }
  }

  .search-results__pagination {
    margin-top: $sds-spacing-unit-4;
  }
}

//--------------------
// Tablet
//--------------------

@media screen and (min-width: $device-tablet) {
  .search-filters__panel {
    left: $sds-page-gutter;
    position: absolute;
    top: 0;
    width: px-rem(206);
  }

  .filter-panel__header,
  .search-filters {
    display: none;
  }

  .filter-panel__sections,
  .search-filters__applied {
    padding: 0;
  }

  .search-filters__applied {
    border-top: none;
    padding: 0;
  }

  .search-filters__enablement {
    display: none;
  }

  .search-filters__container,
  .search-results__container {
    margin-left: px-rem(206);
    padding-left: $sds-spacing-unit-10;
  }

  .search-results__pagination--wrapper {
    @include flex-vertical;
    justify-content: space-between;
    margin-top: $sds-spacing-unit-4;
  }
}

//--------------------
// Desktop
//--------------------

@media screen and (min-width: $device-desktop) {
  .search-help__tabs-list {
    justify-content: center;
    padding-left: 0;
  }

  .search-filters__panel {
    left: $sds-desktop-gutter;
    width: px-rem(291);
  }

  .search-filters__container,
  .search-results__container {
    margin-left: px-rem(291);
    padding-left: px-rem(77);
  }
}
