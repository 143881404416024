//!
// @license
// Copyright Squiz australia Pty Ltd. all Rights Reserved.
//

//--------------------
// Global
//--------------------

.main-menu__list {
  @include list-reset;

  li {
    margin: 2px;
  }
}

.main-menu__item {
  @include flex-vertical;
  background: $sds-white;
  color: $sds-grey-600;
  margin: 0;
  padding: px-rem(15) $sds-spacing-unit-5;
  text-decoration: none;
  width: 100%;
}

.main-menu__icon {
  margin-right: 0.75rem;

  svg {
    height: $sds-spacing-unit-6;
    width: $sds-spacing-unit-6;
  }
}

//--------------------
// Mobile & Tablet
//--------------------

@media screen and (max-width: $device-desktop) {
  .toolbar__navigation {
    display: none;
    left: 0;
    position: absolute;
    top: 6px;
    width: 100%;

    &.active {
      display: block;
    }
  }

  .toolbar__navigation--menu {
    height: calc(100vh - 62px);
    overflow-y: scroll;
    position: relative;
  }

  .toolbar__navigation--underlay {
    height: 100%;
    left: 0;
    position: absolute;
    top: 0;
    width: 100%;
  }

  .toolbar__navigation-header {
    @include flex-vertical;
    background: $sds-grey-100;
    padding: px-rem(10) $sds-spacing-unit-5;
  }

  .toolbar__navigation-heading {
    h2 {
      margin: 0;
    }
  }

  .main-menu {
    background: $sds-white;
    border-top: 1px solid $sds-grey-200;
    min-height: 100%;
    padding: $sds-spacing-unit-4 0;
    position: relative;
    z-index: 1;
  }

  .main-menu__section {
    @include flex-vertical;
    padding: $sds-spacing-unit-4 $sds-spacing-unit-5;
  }

  .main-menu__section-label {
    @include sds-text('md', 'semibold');
    padding-right: $sds-spacing-unit-5;
  }

  .main-menu__section-divider {
    background-color: $sds-grey-300;
    flex-grow: 1;
    height: 1px;
  }

  .main-menu__item {
    @include sds-text('lg', 'semibold');
  }
}

//--------------------
// Tablet
//--------------------

@media screen and (min-width: $device-tablet) {
  .toolbar__navigation--menu {
    background: $sds-alpha-black-10;
  }

  .toolbar__navigation-header {
    background: transparent;

    h2 {
      display: none;
    }
  }

  .main-menu {
    width: px-rem(360);
  }
}

//--------------------
// Desktop
//--------------------

@media screen and (min-width: $device-desktop) {
  .toolbar__navigation {
    flex-grow: 1;
  }

  .toolbar__navigation--menu {
    background: $sds-white;
  }

  .main-menu {
    @include flex-vertical;
    width: 100%;
  }

  .main-menu__dropdown {
    background: $sds-white;
    border-radius: $sds-border-radius;
    box-shadow: $sds-shadow-medium;
    left: 0;
    position: absolute;
    top: calc(100% + 0.5rem);
    width: px-rem(220);

    .main-menu__item {
      font-weight: $sds-text-md-semibold-font-weight;
    }
  }

  .main-menu__list {
    display: flex;
  }

  .main-menu__item {
    border-radius: $sds-border-radius;
    padding: px-rem(6) px-rem(14);

    &:hover {
      background-color: $sds-grey-100;
      color: $sds-grey-800;
    }

    &.active {
      background-color: $sds-grey-200;
      border-radius: $sds-spacing-unit-2;
      color: $sds-grey-900;
    }
  }

  .main-menu__dropdown-container {
    position: relative;

    & .main-menu__dropdown-control .main-menu__item {
      padding-right: px-rem(5);
    }

    .dropdown-control--close,
    .main-menu__dropdown {
      display: none;
    }

    .main-menu__list {
      flex-direction: column;
      padding: px-rem(6) 0;
    }

    &.active {
      display: block;

      .main-menu__dropdown {
        display: block;
      }

      .main-menu__dropdown-control .main-menu__item {
        box-shadow: $sds-shadow-medium;
        color: $sds-grey-900;

        &:focus {
          @include sds-base-focus-shadow;
        }

        &:focus:not(:focus-visible) {
          box-shadow: $sds-shadow-medium !important;
        }

        &:hover {
          background: $sds-white;
        }

        svg {
          fill: $sds-grey-900;
        }
      }

      .dropdown-control--close {
        display: flex;
      }

      .dropdown-control--open {
        display: none;
      }
    }
  }
}
