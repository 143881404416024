/*!
 * @license
 * Copyright Squiz Australia Pty Ltd. All Rights Reserved.
 */

//--------------------
// Global
//--------------------

.left-sidenav {
  background: $sds-white;
  color: $sds-grey-600;
  height: 100%;
  overflow-y: auto;
  width: 100%;

  h2 {
    color: $sds-grey-900;
  }
}

.content-header,
.left-sidenav-header {
  @include flex-vertical;
  border-bottom: 1px solid $sds-grey-200;
  margin-bottom: $sds-spacing-unit-2;
}

.content-header__control,
.left-sidenav__control {
  @include sds-icon-button;
  margin-right: $sds-spacing-unit-4;
}

.left-sidenav-tablet {
  background: $sds-alpha-black-50;
  height: calc(100vh - 50px);
  left: 0;
  position: absolute;
  top: 100%;
  width: 100%;
  z-index: 10;

  .left-sidenav {
    border-top: 1px solid $sds-grey-300;
  }
}

.left-sidenav__control {
  svg {
    fill: $sds-grey-900;
  }
}

.left-sidenav-list {
  @include list-reset;
}

.left-sidenav-section {
  @include flex-vertical;
  padding: $sds-spacing-unit-4 $sds-spacing-unit-5;
}

.left-sidenav-section__divider {
  background-color: $sds-grey-300;
  flex-grow: 1;
  height: 1px;
}

.left-sidenav-section__label {
  @include sds-text('md', 'semibold');
  padding-right: $sds-spacing-unit-5;
  text-transform: uppercase;
}

.left-sidenav-item {
  &.active {
    position: relative;

    &::before {
      background: $sds-grey-900;
      border-radius: 0 3px 3px 0;
      content: '';
      height: 100%;
      left: 0;
      position: absolute;
      width: 3px;
      z-index: 1;
    }

    .left-sidenav-link {
      color: $sds-grey-800;
      font-weight: $sds-text-lg-bold-font-weight;

      svg {
        fill: $sds-grey-800;
      }
    }
  }
}

.left-sidenav-link {
  color: $sds-grey-600;
  display: flex;
  padding: $sds-spacing-unit-4 0 $sds-spacing-unit-4 $sds-spacing-unit-4;
  text-align: left;
  width: 100%;

  &:hover {
    background: $sds-grey-200;
  }

  .svg-wrapper {
    margin-right: $sds-spacing-unit-3;
  }

  &.active {
    .left-sidenav-submenu__toggle {
      svg {
        transform: rotate(90deg);
      }
    }

    + .left-sidenav-submenu {
      height: auto;
    }
  }
}

.left-sidenav-label {
  display: inline-block;
  padding-right: $sds-spacing-unit-4;
}

.left-sidenav-submenu__toggle {
  &--top {
    margin-left: auto;
  }

  svg {
    transform: rotate(-90deg);
    transition: transform 0.3s ease;
  }
}

.left-sidenav-submenu {
  @include list-reset;
  height: 0;
  overflow: hidden;

  .svg-wrapper {
    margin-right: $sds-spacing-unit-2;

    svg {
      height: $sds-spacing-unit-4;
      width: $sds-spacing-unit-4;
    }
  }

  .left-sidenav-link {
    padding-left: $sds-spacing-unit-10;
  }

  .left-sidenav-submenu {
    .left-sidenav-link {
      padding-left: px-rem(72);
    }
  }
}

//--------------------
// Mobile & Tablet
//--------------------

@media screen and (max-width: $device-desktop) {
  .left-sidenav {
    height: 100vh;
    left: -100%;
    position: absolute;
    top: 0;
    transition: left 0.5s ease;
    width: 100%;
    z-index: 1;

    &.active {
      left: 0;
    }
  }

  .left-sidenav-link {
    @include sds-text('lg', 'semibold');
  }
}

//--------------------
// Desktop
//--------------------

@media screen and (min-width: $device-desktop) {
  .left-sidenav {
    width: px-rem(270);
  }

  .left-sidenav-link {
    @include sds-text('md', 'semibold');
    line-height: $sds-text-lg-semibold-line-height;
    padding: px-rem(10) 0 px-rem(10) $sds-spacing-unit-4;
  }
}

//--------------------
// Max
//--------------------

@media screen and (min-width: $device-max) {
  .left-sidenav {
    width: px-rem(260);
  }
}
