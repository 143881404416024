//!
// @license
// Copyright Squiz australia Pty Ltd. all Rights Reserved.
//

//--------------------
// Global
//--------------------

@keyframes spinner-border {
  0% {
    transform: rotate(0);
  }

  100% {
    transform: rotate(360deg);
  }
}

.spinner__container {
  display: flex;
  justify-content: center;
  margin: $sds-spacing-unit-6 0;
}

.spinner {
  @include flex-vertical;
  animation: spinner-border 0.8s ease-in-out infinite both;
  border: px-rem(3) solid currentcolor;
  border-radius: 50%;
  border-right-color: transparent;
  color: currentcolor;
  height: $sds-spacing-unit-8;
  width: $sds-spacing-unit-8;
}
