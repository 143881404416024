//--------------------
// Primary UI
//--------------------

// Blue
$sds-blue-100: #e6f1fa;
$sds-blue-200: #8fc0eb;
$sds-blue-300: #0774d2;
$sds-blue-400: #044985;

// Green
$sds-green-100: #e7f4ec;
$sds-green-200: #95d0a9;
$sds-green-300: #098934;
$sds-green-400: #0a4a20;

// Orange
$sds-orange-100: #fcf0e5;
$sds-orange-200: #f5bb8c;
$sds-orange-300: #e96900;
$sds-orange-400: #8f4000;

// Red
$sds-red-100: #fae9e8;
$sds-red-200: #ec9c9b;
$sds-red-300: #d72321;
$sds-red-400: #6b1211;

// Purple
$sds-purple-100: #f0ecf7;
$sds-purple-200: #bcacdd;
$sds-purple-300: #6b47b4;
$sds-purple-400: #3e2969;

//--------------------
// Secondary UI
//--------------------

// Teal
$sds-teal-100: #e6f4f6;
$sds-teal-200: #8fd0d9;
$sds-teal-300: #0696ad;
$sds-teal-400: #024752;

// Yellow
$sds-yellow-100: #fdf7e9;
$sds-yellow-200: #f7dd9c;
$sds-yellow-300: #efb324;
$sds-yellow-400: #a17917;

// Pink
$sds-pink-100: #f8ebf6;
$sds-pink-200: #dfa7d8;
$sds-pink-300: #b93da9;
$sds-pink-400: #6e2464;

//--------------------
// Grey tones
//--------------------

$sds-white: #fff;
$sds-grey-50: #f7f7f7;
$sds-grey-100: #f5f5f5;
$sds-grey-200: #ededed;
$sds-grey-300: #e0e0e0;
$sds-grey-400: #bababa;
$sds-grey-500: #949494;
$sds-grey-600: #707070;
$sds-grey-700: #4f4f4f;
$sds-grey-800: #3d3d3d;
$sds-grey-900: #2b2b2b;
$sds-black: #000;

//--------------------
// Alpha tones
//--------------------

$sds-alpha-black-50: rgb(0 0 0 / 0.5);
$sds-alpha-black-10: rgb(0 0 0 / 0.1);
$sds-alpha-black-4: rgb(0 0 0 / 0.04);
$sds-alpha-blue-100-50: rgb(230 241 250 / 0.5);

//--------------------
// Brands
//--------------------

// Squiz brand colour palette
$sds-dxp-base: #efe9e3;
$sds-dxp-primary: #095c78;
$sds-dxp-accent: #1d9be2;
$sds-dxp-secondary-1: #26324b;
$sds-dxp-secondary-2: #993939;

// SXC
$sds-sxc-primary: #00d1ff;
$sds-sxc-secondary: #004875;

// Matrix
$sds-matrix-primary: #00afdb;
$sds-matrix-secondary: #00649e;

// Funnelback
$sds-funnelback-primary: #dd4122;
$sds-funnelback-secondary: #002b3d;

// Connect
$sds-connect-primary: #b6a3ff;
$sds-connect-secondary: #575894;

// Datastore
$sds-datastore-primary: #04c97a;
$sds-datastore-secondary: #13765c;
