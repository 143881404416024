//!
// @license
// Copyright Squiz australia Pty Ltd. all Rights Reserved.
//

//--------------------
// Global
//--------------------

.info-panels__wrapper {
  padding: $sds-spacing-unit-8 0 $sds-spacing-unit-11 0;
}

.info-panels__intro {
  margin-bottom: $sds-spacing-unit-6;
  text-align: center;
}

.info-panels__title {
  @include sds-base-heading(2);
  color: $sds-grey-700;
  margin: 0;
  padding-bottom: $sds-spacing-unit-4;
}

.info-panels__list {
  @include list-reset;
}

.info-panels__item {
  margin-bottom: $sds-spacing-unit-4;
}

.panel {
  background: $sds-grey-50;
  border-radius: px-rem(8);
  color: $sds-grey-800;
  display: block;
  height: 100%;
  padding: $sds-spacing-unit-5;
  text-decoration: none;
}

.panel__heading {
  @include sds-base-heading(2);
  color: $sds-grey-800;
  display: block;
  margin-bottom: $sds-spacing-unit-3;
}

.panel__text {
  color: $sds-grey-600;
}

.panel__checklist {
  list-style: none;
  margin-top: $sds-spacing-unit-4;
  padding-left: 0;

  li {
    margin-bottom: $sds-spacing-unit-2;
    padding-left: px-rem(26);
    position: relative;

    &:last-child {
      margin-bottom: 0;
    }
  }

  li::before {
    background-image: url("data:image/svg+xml,%3Csvg width='14' height='12' viewBox='0 0 14 12' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M4.32852 9.22566L1.43716 6.3343C1.28148 6.17827 1.07013 6.09059 0.849717 6.09059C0.629309 6.09059 0.417956 6.17827 0.262279 6.3343C-0.0626868 6.65927 -0.0626868 7.18421 0.262279 7.50918L3.74525 10.9921C4.07021 11.3171 4.59516 11.3171 4.92012 10.9921L13.7359 2.1764C14.0608 1.85144 14.0608 1.32649 13.7359 1.00152C13.5802 0.845497 13.3688 0.757812 13.1484 0.757812C12.928 0.757812 12.7167 0.845497 12.561 1.00152L4.32852 9.22566Z' fill='%23098934'/%3E%3C/svg%3E");
    background-repeat: no-repeat;
    background-size: 14px 12px;
    content: '';
    height: px-rem(12);
    left: px-rem(2);
    position: absolute;
    top: px-rem(4);
    width: px-rem(14);
  }
}

//--------------------
// Mobile Only
//--------------------

@media screen and (max-width: ($device-tablet - 1)) {
  .info-panels__item:last-child {
    margin-bottom: 0;
  }
}

//--------------------
// Tablet
//--------------------

@media screen and (min-width: $device-tablet) {
  .info-panels__intro {
    margin-bottom: $sds-spacing-unit-10;
  }

  .info-panels__list {
    display: flex;
    flex-wrap: wrap;
  }

  .info-panels__item {
    margin-right: $sds-spacing-unit-4;
    width: calc(50% - $sds-spacing-unit-2);

    &:nth-child(2n) {
      margin-right: 0;
    }
  }
}

//--------------------
// Desktop
//--------------------

@media screen and (min-width: $device-desktop) {
  .info-panels {
    margin: 0 auto;
    max-width: px-rem(996);
  }

  .info-panels__intro {
    margin: $sds-spacing-unit-4 auto $sds-spacing-unit-10 auto;
    max-width: px-rem(600);
  }

  .info-panels__title {
    @include sds-base-heading(1);
  }

  .info-panels__description {
    @include sds-text('lg');
  }
}
