//!
// @license
// Copyright Squiz australia Pty Ltd. all Rights Reserved.
//

//--------------------
// Global
//--------------------

.breadcrumbs-wrapper {
  margin-bottom: $sds-spacing-unit-6;
}

.breadcrumbs-list {
  @include list-reset;
  @include flex-vertical;
  @include sds-text('sm', 'regular');

  li {
    margin-right: $sds-spacing-unit-6;

    &:last-child {
      margin-right: 0;
    }
  }

  a {
    color: $sds-grey-600;
    position: relative;
    text-decoration: none;

    &:hover {
      text-decoration: underline;
    }

    &::after {
      background-color: $sds-grey-600;
      border-radius: $sds-border-radius;
      content: '';
      height: $sds-spacing-unit-4;
      position: absolute;
      right: -$sds-spacing-unit-2;
      top: 50%;
      transform: rotate(25deg) translateY(-50%);
      width: 1px;
    }
  }
}

//--------------------
// Mobile Only
//--------------------

@media screen and (max-width: ($device-tablet - 1)) {
  .breadcrumbs-wrapper {
    margin-top: $sds-spacing-unit-4;
  }
}

//--------------------
// Tablet
//--------------------

@media screen and (min-width: $device-tablet) {
  .breadcrumbs-wrapper {
    margin-top: $sds-spacing-unit-2;
  }
}
