//!
// @license
// Copyright Squiz australia Pty Ltd. all Rights Reserved.
//

//--------------------
// Global
//--------------------

.toolbar__search-control {
  @include sds-icon-button;
}

.toolbar__search-container {
  background: $sds-white;
  display: none;
  left: 0;
  position: absolute;
  top: 0;
  width: 100%;
  z-index: 1;

  &.active {
    @include flex-vertical;
  }
}

.toolbar__search-form {
  display: flex;
  width: 100%;
}

.toolbar__search-query {
  display: flex;
  flex-grow: 1;
  margin-right: $sds-spacing-unit-2;
  position: relative;

  input {
    @include sds-base-input;
    width: 100%;
  }
}

.search__suggestions {
  position: absolute;
  top: 100%;
  width: 100%;
}

.autocomplete-container {
  background: $sds-white;
  border-radius: $sds-border-radius;
  box-shadow: $sds-shadow-small;
  display: none;
  margin-top: px-rem(8);

  &.active {
    display: block;
  }
}

.autocomplete-results__list {
  @include list-reset;
}

.autocomplete-results__item {
  color: $sds-grey-600;
  display: block;
  padding: px-rem(13) px-rem(18) px-rem(10) px-rem(18);
  text-align: left;
  width: 100%;

  &:hover {
    background: $sds-alpha-blue-100-50;
    color: $sds-grey-800;
  }
}

//--------------------
// Mobile & Tablet
//--------------------

@media screen and (max-width: $device-desktop) {
  .toolbar__search-container {
    padding: px-rem(9) $sds-spacing-unit-5;
  }

  .toolbar__search-query {
    input {
      @include sds-text('xlg');
      padding: px-rem(6) px-rem(10);
    }
  }

  .toolbar__search-submit {
    display: none;
  }
}

//--------------------
// Desktop
//--------------------

@media screen and (min-width: $device-desktop) {
  .toolbar__search-query {
    input {
      padding-right: px-rem(80);
    }
  }

  .toolbar__search-submit {
    @include sds-primary-button('strong');
    position: absolute;
    right: 0;
    top: 0;
  }
}
