//!
// @license
// Copyright Squiz australia Pty Ltd. all Rights Reserved.
//

//--------------------
// Global
//--------------------

.skip-links {
  background: $sds-grey-50;
}

.skip-links__list {
  @include list-reset;
}

.skip-links__item {
  @include sds-base-button;
  display: inline-block;
  text-decoration: none;

  &.sr-reveal:focus {
    margin: $sds-spacing-unit-05;
  }
}
