//!
// @license
// Copyright Squiz australia Pty Ltd. all Rights Reserved.
//

//--------------------
// Global
//--------------------

.cards-collection--light {
  background: $sds-grey-100;

  .card__wrapper {
    background: $sds-white;
    border: 1px solid $sds-grey-300;
    box-shadow: $sds-shadow-small;
  }
}

.cards-collection--dark {
  background: $sds-white;

  .card__wrapper {
    background: $sds-grey-50;
  }
}

.cards-collection__wrapper {
  padding: $sds-spacing-unit-8 0 px-rem(38) 0;
}

.cards-collection {
  h2 {
    color: $sds-grey-700;
    margin: 0;
    padding-bottom: $sds-spacing-unit-4;
  }
}

.cards__list {
  @include list-reset;
}

.cards__item {
  margin-bottom: $sds-spacing-unit-4;
}

.card__wrapper {
  @include flex-vertical;
  border-radius: $sds-spacing-unit-2;
  color: $sds-grey-800;
  height: 100%;
  padding: $sds-spacing-unit-4;
  text-decoration: none;

  &.card--top {
    align-items: flex-start;
  }

  &:focus {
    @include sds-base-focus-shadow;
  }

  &:hover {
    .card__title {
      text-decoration: underline;
    }
  }
}

.card__icon {
  flex-shrink: 0;
  margin-right: $sds-spacing-unit-4;

  svg {
    fill: $sds-grey-800;
    height: $sds-spacing-unit-12;
    width: $sds-spacing-unit-12;
  }
}

.cards-collection--4-col {
  .card__icon {
    svg {
      height: $sds-spacing-unit-11;
      width: $sds-spacing-unit-11;
    }
  }
}

.card__title {
  @include sds-text('lg', 'semibold');
  color: $sds-grey-800;
  display: block;
}

//--------------------
// Mobile Only
//--------------------

@media screen and (max-width: ($device-tablet - 1)) {
  .cards__item:last-child {
    margin-bottom: 0;
  }
}

//--------------------
// Tablet
//--------------------

@media screen and (min-width: $device-tablet) {
  .cards-collection__wrapper {
    padding-bottom: $sds-spacing-unit-7;
  }

  .cards__list {
    display: flex;
    flex-wrap: wrap;
  }

  .cards__item {
    margin-right: 2%;
  }
}

//--------------------
// Tablet Only
//--------------------

@media screen and (min-width: $device-tablet) and (max-width: ($device-desktop - 1)) {
  .cards__item {
    width: 49%;

    &:nth-child(2n) {
      margin-right: 0;
    }
  }
}

//--------------------
// Desktop
//--------------------

@media screen and (min-width: $device-desktop) {
  .cards-collection__wrapper {
    padding-bottom: $sds-spacing-unit-11;

    &.cards-collection--no-heading {
      padding-top: px-rem(55);
    }
  }

  .cards-collection {
    margin: 0 auto;
    max-width: $sds-max-width-content;
  }

  .cards-collection--3-col {
    .cards__item {
      width: 32%;

      &:nth-child(3n) {
        margin-right: 0;
      }
    }
  }

  .cards-collection--4-col {
    .cards-collection {
      max-width: px-rem(1238);
    }

    .cards__item {
      width: 23.5%;

      &:nth-child(4n) {
        margin-right: 0;
      }
    }

    .card__wrapper {
      align-items: center;
      border-radius: $sds-spacing-unit-3;
      flex-direction: column;
      text-align: center;
    }

    .card__icon {
      margin-bottom: $sds-spacing-unit-3;
      margin-right: 0;
    }
  }
}
