//--------------------
// Base
//--------------------

@mixin sds-base-button() {
  @include sds-text('md', 'semibold');
  border-radius: $sds-border-radius;
  color: $sds-grey-600;
  padding: $sds-spacing-unit-2 $sds-spacing-unit-3;
}

@mixin sds-soft-button($color: $sds-grey-700) {
  @include sds-text('md', 'bold');
  background: $sds-alpha-black-10;
  border-radius: $sds-border-radius;
  color: $color;
  padding: $sds-spacing-unit-2 $sds-spacing-unit-3;
}

@mixin sds-strong-button($background: $sds-blue-300) {
  @include sds-text('md', 'bold');
  background: $background;
  border-radius: $sds-border-radius;
  color: $sds-white;
  padding: $sds-spacing-unit-2 $sds-spacing-unit-3;
}

@mixin sds-transparent-button() {
  @include sds-base-button;
}

@mixin sds-primary-button($type: 'strong') {
  @if $type == 'strong' {
    @include sds-strong-button($sds-blue-300);
  }

  @if $type == 'soft' {
    @include sds-strong-button($sds-blue-300);
  }

  @if $type == 'transparent' {
    @include sds-transparent-button;
  }
}
