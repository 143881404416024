//!
// @license
// Copyright Squiz australia Pty Ltd. all Rights Reserved.
//

//--------------------
// Global
//--------------------

input[type='text'].data-list__input {
  @include sds-text('lg', 'semibold');
  border-color: $sds-grey-200;
  padding: $sds-spacing-unit-4 $sds-spacing-unit-3 $sds-spacing-unit-4 $sds-spacing-unit-11;
}

.data-list__form {
  display: flex;
  position: relative;
  width: 100%;
}

.data-list__input {
  flex-grow: 1;
}

.data-list-filter__icon {
  left: $sds-spacing-unit-3;
  position: absolute;
  top: px-rem(18);
}

.data-list__results {
  margin: $sds-spacing-unit-5 0;
}

.data-list-results__list {
  @include list-reset;
}

.data-list-results__item {
  margin-bottom: $sds-spacing-unit-3;
}

//--------------------
// Mobile Only
//--------------------

@media screen and (max-width: ($device-tablet - 1)) {
  .data-list__form {
    flex-direction: column;
    margin: $sds-spacing-unit-6 0;
  }

  .data-list-results__item {
    width: 100%;
  }
}

//--------------------
// Mobile Tablet Only
//--------------------

@media screen and (max-width: ($device-desktop - 1)) {
  .data-listing__header {
    display: none;
  }
}

//--------------------
// Tablet
//--------------------

@media screen and (min-width: $device-tablet) {
  input[type='text'].data-list__input {
    padding-right: px-rem(240);
  }

  .data-list__form {
    margin-top: $sds-spacing-unit-4;
  }

  .data-list__filter {
    .dropdown-container {
      position: absolute;
      right: 0;
      top: 2px;
      width: px-rem(230);
    }
  }

  .data-list-results__list {
    display: flex;
    flex-wrap: wrap;
  }

  .data-list-results__item {
    @include flex-vertical;
  }
}

//--------------------
// Tablet only
//--------------------

@media screen and (min-width: $device-tablet) and (max-width: ($device-desktop - 1)) {
  .data-list-results__item {
    margin-right: 1%;
    width: 49.5%;

    &:nth-child(2n) {
      margin-right: 0;
    }
  }
}

//--------------------
// Desktop only
//--------------------

@media screen and (min-width: $device-desktop) and (max-width: ($device-wide - 1)) {
  .data-list-results__item {
    margin-right: 1%;
    width: 32.66%;

    &:nth-child(3n) {
      margin-right: 0;
    }
  }
}

//--------------------
// Wide
//--------------------

@media screen and (min-width: $device-wide) {
  .data-list-results__item {
    margin-right: 1%;
    width: 24.25%;

    &:nth-child(4n) {
      margin-right: 0;
    }
  }
}
