//!
// @license
// Copyright Squiz australia Pty Ltd. all Rights Reserved.
//

//--------------------
// Global
//--------------------

.header {
  border-bottom: 1px solid $sds-grey-200;
  position: relative;
  z-index: 10;
}

.header-strip__wrapper {
  height: 6px;
  overflow: hidden;
}

.header-strip {
  height: 3px;

  &--dark {
    background-color: $sds-dxp-primary;
  }

  &--light {
    background-color: $sds-dxp-accent;
  }
}

.header__toolbar {
  @include flex-vertical;
  padding: px-rem(10) 0;
}

.header-nav__control {
  @include sds-icon-button;
  background-color: $sds-grey-300;
  margin-right: $sds-spacing-unit-4;
}

.toolbar__branding {
  @include flex-vertical;
}

.branding__link {
  @include flex-vertical;
  color: $sds-grey-900;
  text-decoration: none;
}

.branding__logos {
  margin-right: $sds-spacing-unit-2;

  svg {
    fill: $sds-grey-900;
  }
}

.branding__title {
  h3 {
    margin: 0;
  }
}

//--------------------
// Mobile & Tablet
//--------------------

@media screen and (max-width: $device-desktop) {
  .toolbar__resources {
    margin-left: auto;
  }
}

//--------------------
// Desktop
//--------------------

@media screen and (min-width: $device-desktop) {
  .toolbar__resources {
    @include flex-vertical;
    position: relative;
    width: 100%;
  }

  .toolbar__branding {
    border-right: 2px solid $sds-grey-200;
    flex-shrink: 0;
    margin-right: $sds-spacing-unit-4;
    padding-right: px-rem(30);
  }
}
