//--------------------
// Base font
//--------------------

$base-font-scale: 1; // The font scale multiplier (e.g. 2 = 32px/2em/2rem)
$base-font-pixel: 16; // Used for font size calculations & conversions
$base-font-size: $base-font-pixel + px;
$base-font-colour: $sds-grey-800;
$base-font-family: Open Sans, Arial, sans-serif;

//--------------------
// Spacers
//--------------------

$sds-base-spacer: 0.25; // 4px

$sds-spacing-unit-0: ($sds-base-spacer * 0) + rem; // 0px       , 0rem
$sds-spacing-unit-05: ($sds-base-spacer * 0.5) + rem; // 2px    , 0.125rem
$sds-spacing-unit-1: ($sds-base-spacer * 1) + rem; // 4px       , 0.25rem
$sds-spacing-unit-2: ($sds-base-spacer * 2) + rem; // 8px       , 0.5rem
$sds-spacing-unit-3: ($sds-base-spacer * 3) + rem; // 12px      , 0.75rem
$sds-spacing-unit-4: ($sds-base-spacer * 4) + rem; // 16px      , 1rem
$sds-spacing-unit-5: ($sds-base-spacer * 5) + rem; // 20px      , 1.25rem
$sds-spacing-unit-6: ($sds-base-spacer * 6) + rem; // 24px      , 1.5rem
$sds-spacing-unit-7: ($sds-base-spacer * 7) + rem; // 28px      , 1.75rem
$sds-spacing-unit-8: ($sds-base-spacer * 8) + rem; // 32px      , 2rem
$sds-spacing-unit-9: ($sds-base-spacer * 9) + rem; // 36px      , 2.25rem
$sds-spacing-unit-10: ($sds-base-spacer * 10) + rem; // 40px    , 2.5rem
$sds-spacing-unit-11: ($sds-base-spacer * 11) + rem; // 44px    , 2.75rem
$sds-spacing-unit-12: ($sds-base-spacer * 12) + rem; // 48px    , 3rem

//--------------------
// Utilities
//--------------------

$sds-border-radius: $sds-spacing-unit-1;
$sds-max-width-content: 1152px;
$sds-max-width-page: 1440px;
$sds-page-gutter: $sds-spacing-unit-5;
$sds-desktop-gutter: ($sds-base-spacer * 2.5) + rem; // 10px

//--------------------
// Shadows
//--------------------

/* stylelint-disable color-function-notation */
/* stylelint-disable length-zero-no-unit */
$sds-shadow-outline-blue: 0 0 0 2px rgba($sds-blue-300, 1);
$sds-shadow-small: 0px 0px 2px rgba(0, 0, 0, 0.12), 0px 1px 4px rgba(0, 0, 0, 0.08);
$sds-shadow-medium: 0px 0px 4px rgba(0, 0, 0, 0.04), 0px 1px 12px rgba(0, 0, 0, 0.12);
$sds-shadow-grey: 0px 1px 12px rgba(0, 0, 0, 0.05);
/* stylelint-enable */

//--------------------
// Size breakpoints
//--------------------

$bp-small: 576px;
$bp-medium: 768px;
$bp-large: 1024px;
$bp-extra-large: 1366px;

//--------------------
// Device breakpoints
//--------------------

$device-tablet: $bp-medium;
$device-desktop: $bp-large;
$device-wide: $bp-extra-large;
$device-max: $sds-max-width-page;
