//!
// @license
// Copyright Squiz australia Pty Ltd. all Rights Reserved.
//

//--------------------
// Global
//--------------------

.call-to-action {
  background: $sds-grey-50;
  border: 1px solid $sds-grey-300;
  border-radius: $sds-border-radius;
  padding: $sds-spacing-unit-6;
}

.call-to-action__header {
  color: $sds-grey-800;
  margin-bottom: $sds-spacing-unit-6;
  text-align: center;
}

.call-to-action__title {
  @include sds-base-heading(3);
  display: block;
  margin-bottom: $sds-spacing-unit-1;
}

.call-to-action__subtitle {
  @include sds-text('lg', 'regular');
}

.call-to-action__cards {
  @include list-reset;
  display: flex;
}

//--------------------
// Mobile Only
//--------------------

@media screen and (max-width: ($device-tablet - 1)) {
  .call-to-action__cards {
    flex-direction: column;
  }

  .call-to-action__item {
    margin-bottom: $sds-spacing-unit-5;
    width: 100%;

    &:last-child {
      margin-bottom: 0;
    }
  }

  .integration-card__body {
    flex-grow: 1;
  }
}

//--------------------
// Tablet
//--------------------

@media screen and (min-width: $device-tablet) {
  .call-to-action__item {
    flex-grow: 1;
    margin-right: $sds-spacing-unit-5;
    width: 50%;

    &:last-child {
      margin-right: 0;
    }
  }
}
