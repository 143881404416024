//!
// @license
// Copyright Squiz australia Pty Ltd. all Rights Reserved.
//

//--------------------
// Global
//--------------------

.banner-with-search__wrapper.banner--2-col {
  .banner {
    justify-content: center;
  }

  .banner__graphic {
    display: none;
  }

  .banner__tag {
    @include sds-text('xlg', 'semibold');
    display: block;
  }

  .banner__heading {
    @include sds-heavy-heading(1);
    display: block;
    margin-bottom: $sds-spacing-unit-3;
    margin-top: $sds-spacing-unit-2;
  }

  .banner__description {
    @include sds-text('xlg');
    display: block;
  }
}

//--------------------
// Mobile Only
//--------------------

@media screen and (max-width: ($device-tablet - 1)) {
  .banner-with-search__wrapper.banner--2-col {
    .banner__heading,
    .banner__description {
      @include sr-only;
    }
  }
}

//------------------------
// Mobile and Tablet Only
//------------------------

@media screen and (max-width: ($device-desktop - 1)) {
  .banner-with-search__wrapper.banner--2-col {
    .banner__tag {
      @include sr-only;
    }
  }
}

//--------------------
// Tablet
//--------------------

@media screen and (min-width: $device-tablet) {
  .banner-with-search__wrapper.banner--2-col {
    .banner__content {
      max-width: px-rem(534);
    }

    .banner__content-text {
      margin-bottom: $sds-spacing-unit-7;
    }

    .banner__form {
      padding: 0 $sds-spacing-unit-3;
    }
  }
}

//--------------------
// Desktop
//--------------------

@media screen and (min-width: $device-desktop) {
  .banner-with-search__wrapper.banner--2-col {
    .banner {
      align-items: center;
      height: px-rem(360);
    }

    .banner__graphic {
      display: inline-block;
      margin-left: px-rem(60);
      margin-top: $sds-spacing-unit-2;
      padding: 0 $sds-spacing-unit-7;
      z-index: 1;

      img {
        height: px-rem(302);
      }
    }

    .banner__content {
      margin: 0;
      max-width: px-rem(550);
    }

    .banner__content-text {
      text-align: left;
    }

    .banner__form {
      padding: 0;
    }
  }
}
