//--------------------
// Global
//--------------------

html {
  -webkit-font-smoothing: antialiased;
}

html,
body {
  height: 100%;
}

body {
  color: $base-font-colour;
  font-family: $base-font-family;
  font-size: $base-font-size;
  overflow-y: scroll;
  padding: 0;
  position: relative;
  z-index: 1;

  &.active {
    position: fixed;
    width: 100%;
  }
}

img {
  max-width: 100%;
}

button {
  @include button-reset;
}

h1 {
  @include sds-base-heading(1);
}

h2 {
  @include sds-base-heading(2);
}

h3 {
  @include sds-base-heading(3);
}

h4 {
  @include sds-base-heading(4);
}

h5 {
  @include sds-base-heading(5);
}

h6 {
  @include sds-base-heading(6);
}

a {
  color: $sds-blue-300;
}

input {
  &[type='text'] {
    @include sds-base-input;
  }
}

*:focus:not(.main, .main-menu) {
  @include sds-base-focus;
}

*:focus:not(:focus-visible) {
  box-shadow: none !important;
}

.main,
.main-menu {
  &:focus {
    outline: none;
  }
}

.tablet-only,
.desktop-only {
  display: none !important;
}

.sr-only {
  border: 0;
  clip: rect(0 0 0 0);
  height: 1px;
  margin: -1px;
  overflow: hidden;
  position: absolute;
  width: 1px;

  &.sr-reveal:focus {
    clip: auto;
    height: auto;
    margin: 0;
    overflow: visible;
    position: static;
    width: auto;
  }
}

.svg-wrapper {
  display: flex;

  svg {
    fill: $sds-grey-600;
  }
}

.page-wrapper {
  display: flex;
  flex-direction: column;
  min-height: 100%;

  @include sds-text('md');
}

.main {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
}

.inside {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  padding-bottom: $sds-spacing-unit-5;
  position: relative;

  h1 {
    margin-top: 0;
  }
}

.sidebar {
  background: $sds-white;
}

.container {
  max-width: $sds-max-width-page;
  width: 100%;
}

.container-page {
  margin: 0 auto;
  max-width: $sds-max-width-page;
  width: 100%;
}

.container-page-padded {
  margin: 0 auto;
  max-width: $sds-max-width-page;
  padding: 0 $sds-page-gutter;
  width: 100%;
}

//--------------------
// Tablet
//--------------------

@media screen and (min-width: $device-tablet) {
  body {
    z-index: 2;
  }

  .mobile-only-strict {
    display: none !important;
  }

  .tablet-only {
    display: block !important;
  }
}

//--------------------
// Desktop
//--------------------

@media screen and (min-width: $device-desktop) {
  body {
    z-index: 3;
  }

  .mobile-only,
  .tablet-only {
    display: none !important;
  }

  .desktop-only {
    display: block !important;
  }

  .inside {
    flex-direction: row;
    padding: $sds-spacing-unit-5 0;
  }

  .sidebar {
    background: $sds-white;
    border-right: 1px solid $sds-grey-200;
    flex-shrink: 0;

    & + .content {
      flex-grow: 1;
      padding-left: $sds-spacing-unit-7;
    }
  }
}

//--------------------
// Max
//--------------------

@media screen and (min-width: $device-max) {
  .container-page-padded {
    padding: 0 $sds-desktop-gutter;
  }
}
