//--------------------
// Headings
//--------------------

$sds-h1-font-family: $base-font-family;
$sds-h1-font-size: 1.625rem;
$sds-h1-font-weight: 600;
$sds-h1-letter-spacing: -0.2px;
$sds-h1-line-height: 2rem;

$sds-h2-font-family: $base-font-family;
$sds-h2-font-size: 1.25rem;
$sds-h2-font-weight: 600;
$sds-h2-letter-spacing: -0.5px;
$sds-h2-line-height: 1.375rem;

$sds-h3-font-family: $base-font-family;
$sds-h3-font-size: 1.125rem;
$sds-h3-font-weight: 600;
$sds-h3-letter-spacing: -0.2px;
$sds-h3-line-height: 1.375rem;

$sds-h4-font-family: $base-font-family;
$sds-h4-font-size: 1rem;
$sds-h4-font-weight: 700;
$sds-h4-letter-spacing: -0.2px;
$sds-h4-line-height: 1.25rem;

$sds-h5-font-family: $base-font-family;
$sds-h5-font-size: 1rem;
$sds-h5-font-weight: 600;
$sds-h5-letter-spacing: -0.2px;
$sds-h5-line-height: 1.25rem;

$sds-h6-font-family: $base-font-family;
$sds-h6-font-size: 0.875rem;
$sds-h6-font-weight: 600;
$sds-h6-letter-spacing: -0.2px;
$sds-h6-line-height: 1.25rem;

//--------------------
// Headings - Text Heavy Pages
//--------------------

$sds-h1-heavy-font-family: $base-font-family;
$sds-h1-heavy-font-size: 2.25rem;
$sds-h1-heavy-font-weight: 600;
$sds-h1-heavy-letter-spacing: -0.2px;
$sds-h1-heavy-line-height: 2.8125rem;

$sds-h2-heavy-font-family: $base-font-family;
$sds-h2-heavy-font-size: 1.625rem;
$sds-h2-heavy-font-weight: 600;
$sds-h2-heavy-letter-spacing: -0.2px;
$sds-h2-heavy-line-height: 2rem;

$sds-h3-heavy-font-family: $base-font-family;
$sds-h3-heavy-font-size: 1.25rem;
$sds-h3-heavy-font-weight: 600;
$sds-h3-heavy-letter-spacing: -0.5px;
$sds-h3-heavy-line-height: 1.5rem;

$sds-h4-heavy-font-family: $base-font-family;
$sds-h4-heavy-font-size: 1.125rem;
$sds-h4-heavy-font-weight: 600;
$sds-h4-heavy-letter-spacing: -0.2px;
$sds-h4-heavy-line-height: 1.375rem;

$sds-h5-heavy-font-family: $base-font-family;
$sds-h5-heavy-font-size: 1rem;
$sds-h5-heavy-font-weight: 700;
$sds-h5-heavy-letter-spacing: -0.2px;
$sds-h5-heavy-line-height: 1.25rem;

$sds-h6-heavy-font-family: $base-font-family;
$sds-h6-heavy-font-size: 1rem;
$sds-h6-heavy-font-weight: 600;
$sds-h6-heavy-letter-spacing: -0.2px;
$sds-h6-heavy-line-height: 1.25rem;

//--------------------
// Text - XLG
//--------------------

$sds-text-xlg-regular-font-family: $base-font-family;
$sds-text-xlg-regular-font-size: 1.125rem;
$sds-text-xlg-regular-font-weight: 400;
$sds-text-xlg-regular-letter-spacing: -0.4px;
$sds-text-xlg-regular-line-height: 1.75rem;

$sds-text-xlg-semibold-font-family: $base-font-family;
$sds-text-xlg-semibold-font-size: 1.125rem;
$sds-text-xlg-semibold-font-weight: 600;
$sds-text-xlg-semibold-letter-spacing: -0.4px;
$sds-text-xlg-semibold-line-height: 1.6875rem;

$sds-text-xlg-bold-font-family: $base-font-family;
$sds-text-xlg-bold-font-size: 1.125rem;
$sds-text-xlg-bold-font-weight: 700;
$sds-text-xlg-bold-letter-spacing: -0.4px;
$sds-text-xlg-bold-line-height: 1.6875rem;

//--------------------
// Text - LG
//--------------------

$sds-text-lg-regular-font-family: $base-font-family;
$sds-text-lg-regular-font-size: 1rem;
$sds-text-lg-regular-font-weight: 400;
$sds-text-lg-regular-letter-spacing: -0.4px;
$sds-text-lg-regular-line-height: 1.5rem;

$sds-text-lg-semibold-font-family: $base-font-family;
$sds-text-lg-semibold-font-size: 1rem;
$sds-text-lg-semibold-font-weight: 600;
$sds-text-lg-semibold-letter-spacing: -0.4px;
$sds-text-lg-semibold-line-height: 1.5rem;

$sds-text-lg-bold-font-family: $base-font-family;
$sds-text-lg-bold-font-size: 1rem;
$sds-text-lg-bold-font-weight: 700;
$sds-text-lg-bold-letter-spacing: -0.4px;
$sds-text-lg-bold-line-height: 1.5rem;

//--------------------
// Text - MD
//--------------------

$sds-text-md-regular-font-family: $base-font-family;
$sds-text-md-regular-font-size: 0.875rem;
$sds-text-md-regular-font-weight: 400;
$sds-text-md-regular-letter-spacing: -0.2px;
$sds-text-md-regular-line-height: 1.25rem;

$sds-text-md-semibold-font-family: $base-font-family;
$sds-text-md-semibold-font-size: 0.875rem;
$sds-text-md-semibold-font-weight: 600;
$sds-text-md-semibold-letter-spacing: -0.2px;
$sds-text-md-semibold-line-height: 1.25rem;

$sds-text-md-bold-font-family: $base-font-family;
$sds-text-md-bold-font-size: 0.875rem;
$sds-text-md-bold-font-weight: 700;
$sds-text-md-bold-letter-spacing: -0.2px;
$sds-text-md-bold-line-height: 1.25rem;

//--------------------
// Text - SM
//--------------------

$sds-text-sm-regular-font-family: $base-font-family;
$sds-text-sm-regular-font-size: 0.8125rem;
$sds-text-sm-regular-font-weight: 400;
$sds-text-sm-regular-letter-spacing: 0;
$sds-text-sm-regular-line-height: 1rem;

$sds-text-sm-semibold-font-family: $base-font-family;
$sds-text-sm-semibold-font-size: 0.8125rem;
$sds-text-sm-semibold-font-weight: 600;
$sds-text-sm-semibold-letter-spacing: 0;
$sds-text-sm-semibold-line-height: 1rem;

$sds-text-sm-bold-font-family: $base-font-family;
$sds-text-sm-bold-font-size: 0.8125rem;
$sds-text-sm-bold-font-weight: 700;
$sds-text-sm-bold-letter-spacing: 0;
$sds-text-sm-bold-line-height: 1rem;
